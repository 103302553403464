import { Swipe, SwipeItem, Image, Toast, Icon} from 'vant'

export default {
  components:{
    'van-swipe':Swipe,
    'van-image':Image,
    'van-icon':Icon,
    'van-swipe-item':SwipeItem,
  },
  data () {
    return {
      rate:3,
      params:{
        groupId:'',
        token:this.$global.token
      },
      groupDetailId:'',
      code:'TC',
      id:0,
      apis:{
        findGroup:'/group/findGroup',//路线明细
      },
      colorState:false,
      bizTypes:9,
      imgs:[],
      from: null, // 判断是从列表还是首页点击进来的
      videos:[],
      content:{
        attachments:[],
        isPraise:0,
        isCollect:0,
        star:0,
        collect:0,
        praise:0,
      },
      commentList:[],//评论列表
    }
  },
  mounted (){
    this.params.groupId=this.$route.query.groupId;
    this.from = this.$route.query.from; // 来源
    // this.id=sessionStorage.getItem('key')?sessionStorage.getItem('key'):0
    this.id=sessionStorage.getItem('key')?sessionStorage.getItem('key'):1
    this.initDetasils();
    window.addEventListener('scroll',this.scrollFunc,true)
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  destroyed() { //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
    window.removeEventListener('scroll', this.scrollFunc)
  },

  methods:{
    exit(){
      this.$router.go(-1)
    },
    scrollFunc(){
      let top = document.body.scrollTop||document.documentElement.scrollTop
      if(top>30){
        this.colorState = true
      }else{
        this.colorState = false
      }
    },
    goMap(){
      let isMap = JSON.parse(localStorage.getItem('map')) ? true : false;
      if(!isMap) return Toast('定位失败，无法导航');
      let locations = JSON.parse(localStorage.getItem('map')).position;
      let ipGo=null;
      // ipGo = `https://uri.amap.com/navigation?from=${locations.lng},${locations.lat},起点&to=${this.scenic.longitude},${this.scenic.latitude},${this.scenic.name}&callnative=1`
      ipGo = `https://www.amap.com/dir?from[name]=起点&from[lnglat]=${locations.lng},${locations.lat}&to[name]=${this.scenic.name}&to[lnglat]=${this.scenic.longitude},${this.scenic.latitude}&policy=1&type=car&callnative=1`

      // window.open(ipGo)
      this.$router.push({
        path:'/outWindow',
        query:{
          interlinkage:encodeURI(ipGo)
        }
      })
    },
    initDetasils () {//初始化路线详情
      const me = this;
      me.$get({
        url:me.apis.findGroup,
        params:me.params
      }).then(rsp=>{
        me.$nextTick(()=>{
          me.content = rsp
          if(rsp && rsp.attachments && rsp.attachments.length > 0){
            var files = rsp.attachments;
            for(var i = 0, n = files.length; i < n; i++){
              if(files[i].fileType && files[i].fileType == 1){
                me.imgs.push(files[i].url);
              }else if(files[i].fileType && files[i].fileType == 2){
                me.videos.push(files[i].url);
              }
            }
          }
          if(me.imgs.length <= 0){
            me.imgs.push(me.content.cover);
          }
          if(me.content.groupDetails&&me.content.groupDetails.length){
            me.groupDetailId = me.content.groupDetails[0].groupDetailId
          }

        })

      })
    },
    callPhone (phoneNumber) {//拨打电话
      window.location.href = 'tel://' + phoneNumber
    },

    routerPush(v){
      const me = this;
      let arr = {
        sellerId:me.content.sellerId,
        longitude:me.content.seller.longitude,
        latitude:me.content.seller.latitude
      };
      this.$router.push({path:'/lineTwoCom',query:{obj:JSON.stringify(arr)}})
    },
    routerDetails(obj){//票务详情页面
      if(!this.$global.token){
        Toast('请先登陆')
        return
      }
      this.$router.push(`/mallDetails/?commodityId=${obj.commodityId}`)
    }


  }
}
